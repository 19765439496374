
import './App.css';
import './img/tattoo jelly swiss switzerland mini Mini Micro male small tatoos new tatto persfect cheap tattoo.jpg'
import Button from 'react-bootstrap/Button';





function App() {

  return (
    <div className="background">
     <div className="text">
      <div>
        <h2 className='text-naslov'>Jelly Tattoo</h2>
        <div className='mobile-res'>
            <p className='text-naslov1'>Mini-Tattoos Ihrer Wahl ab 50 Franken / Mini Tetovaze vec od 50 fr </p>
           
        </div>
        <div>
            <p className='text-naslov1'>Mini-Tattoos Ihrer Wahl ab 50 Franken / Mini Tetovaze vec od 50 fr </p>
            
        </div>
          <p className='tit-2'>Wir kommen bei Bedarf zu Ihnen nach Hause.</p>
        
        <div className='wapp'>
            <a
              href="https://wa.me/+41763712307"
              class="whatsapp_float"
              target="_blank"
              rel="noopener noreferrer"
            >
              <i class="fa fa-whatsapp whatsapp-icon"></i>
            </a>
        </div>
      </div>
        
     </div>  
     <div className='footer'>
        <h3 className='info'>Für alle Informationen können Sie die Telefonnummer +41764629631
        anrufen oder eine E-Mail senden <a href="mailto:jellyonline10@gmail.com">Send Email</a></h3>

     </div>
    </div>
  )
}

export default App;
